/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useTrail } from 'react-spring';

import HomeProjectItem from './home-project-item';

const HomeProjectGrid = ({ projects }) => {
  const trail = useTrail(projects.length, {
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  return (
    <div
      sx={{
        display: `grid`,
        gridTemplateColumns: [
          // `repeat(auto-fit, minmax(320px, 1fr))`,
          `repeat(auto-fit, minmax(320px, 1fr))`,
          `repeat(auto-fit, minmax(320px, 1fr))`,
          `repeat(auto-fit, minmax(320px, 1fr))`,
          `repeat(auto-fit, minmax(33%, 1fr))`,
        ],
        width: `100%`,
      }}
    >
      {trail.map((style, index) => (
        <HomeProjectItem
          style={style}
          eager={index === 0}
          node={projects[index]}
          key={projects[index].slug}
        />
      ))}
    </div>
  );
};

export default HomeProjectGrid;
