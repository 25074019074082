import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import HomeProjectGrid from '../components/homepage/home-project-grid';

const HomePage = ({ data }) => {
  const { projects } = data.contentfulHomePage;

  return (
    <Layout
      sx={{
        display: `grid`,
        gridTemplateColumns: `repeat(auto-fit, minmax(280px, 1fr))`,
        width: `100%`,
      }}
    >
      <HomeProjectGrid projects={projects} />
    </Layout>
  );
};

export default HomePage;

export const Head = () => <title>Home Page</title>;

export const query = graphql`
  query ($id: String) {
    contentfulHomePage(id: { eq: $id }) {
      id
      title
      projects {
        title
        slug
        client
        frontpageCover {
          gatsbyImageData(width: 800)
        }
      }
    }
  }
`;
